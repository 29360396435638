<template>
  <div class="main-card card mb-3" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : ''">
    <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-header">
      {{ $t('customers.lang_editProject') }}
    </div>
    <v-divider class="ma-0 pa-0"/>
    <div style="text-align: center;" v-if="loading">
      <v-progress-circular color="primary" indeterminate size="50"></v-progress-circular>
    </div>
    <v-form lazy-validation ref="form" v-model="valid">
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :disabled="true"
                          :label="$t('customers.lang_projectNumber')"
                          @focus="showTouchKeyboard"
                          outlined
                          readonly
                          v-model="projectNumber"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          :label="$t('customers.lang_projectName')"
                          :rules="[rules.required]"
                          @focus="showTouchKeyboard"
                          autocomplete="off"
                          outlined
                          required
                          v-model="projectName"
            />
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="12">
            <date-picker :label="$t('generic.lang_startDate')"
                         v-model="startDate"/>
          </v-col>
          <v-col cols="12">
            <v-text-field :rules="[ v => !!v]" :label="$t('customers.lang_projectPrice')" outlined type="number" v-model="projectPrice"/>
          </v-col>

          <v-col cols="12">
            <v-textarea dense :label="$t('generic.lang_note') + ' ...'" outlined v-model="projectNote"/>
          </v-col>
          <v-col cols="12" lg="8" md="12" order="6" order-lg="4" order-md="6" order-sm="6" sm="12">
            <strong>{{ $t('erp.lang_warecreate_color') }}:</strong>
            <swatches
                inline v-model="color"
                background-color="transparent"
            ></swatches>
          </v-col>
          <v-col cols="6" lg="2" md="3" order="4" order-lg="5" order-md="4" order-sm="4" sm="6">
            <v-switch inset :label="$t('generic.lang_enable')" v-model="active"/>
          </v-col>
          <v-col cols="6" lg="2" md="3" order="5" order-lg="6" order-md="5" order-sm="5" sm="6">
            <v-checkbox :label="(status)? $t('generic.lang_finished'): $t('generic.lang_open')" v-model="status"/>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn :disabled="loading" :loading="loading_delete" @click="deleteData()" color="error">
            {{ $t('generic.lang_delete') }}
          </v-btn>
          <v-btn :disabled="!valid" :loading="loading_update" @click="update()" color="success">
            {{ $t('generic.lang_edit') }}
          </v-btn>
        </v-row>

      </v-container>
    </v-form>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixins/KeyboardMixIns";
import {Events} from "../../plugins/events";
import swatches from "vue-swatches";
import DatePicker from "../common/datepicker"
import moment from "moment";

export default {
  name: "EditCustomerProjectComponent",
  components: {swatches, DatePicker},
  mixins: [mixin],
  data() {
    return {
      projectPrice: null,
      projectNote: null,
      id: null,
      valid: true,
      loading: false,
      loading_delete: false,
      loading_update: false,
      projectName: null,
      projectNumber: null,
      startDate: null,
      color: null,
      status: false,
      active: false,
      rules: {
        required: (val) => {
          return !!val || this.$t('generic.lang_requiredField')
        }
      }
    }
  },
  watch: {
    startDate() {
    //  ////console.log(this.startDate);
    }
  },
  methods: {
    getData() {
      this.id = this.$route.params.id;
      this.loading = true;
      this.axios.post('get/customer/customerProject/', {
        projectUUID: this.id,
      }).then(res => {
        if (res.data.success) {
          let active = (res.data.projectActive === 1) ? true : false;
          let status = (res.data.projectStatus === 2) ? true : false;
          let date = moment.unix(res.data.startDateTimeStamp).format('YYYY-MM-DD');
          this.projectName = res.data.projectName
          this.projectNumber = res.data.projectNumber
          this.startDate = date
          this.color = res.data.projectColor
          this.status = status
          this.active = active;
          this.projectPrice = res.data.projectPrice;
          this.projectNote = res.data.projectNote;
        } else {

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch(err => {

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + " ",
          color: "error"
        });
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      let timestamp = parseInt((new Date(self.startDate).getTime() / 1000).toFixed(0))
      let status = (self.status) ? 2 : 1;
      let active = (self.active) ? 1 : 0;
      this.loading_update = true;
      /**
       *
       * /update/customer/customerProject/

       Supply: Project UUID as  $_POST['projectUUID']
       Project Name as: $_POST['projectName']
       Project Color:  $_POST['projectColor']
       Project Enabled or disabled: $_POST['projectActive'] //1 enabled // 0 - disabled
       Project Status open or finished: $_POST['projectStatus'] //1 - open  // 2- finished
       *
       */

      this.axios.post('update/customer/customerProject/', {
        projectUUID: this.id,
        projectName: self.projectName,
        projectColor: self.color,
        projectTimestamp: timestamp,
        projectActive: active,
        projectStatus: status,
        projectPrice: this.projectPrice,
        projectNote: this.projectNote
      }).then(res => {
        if (res.data.status) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + " ",
            color: "error"
          });
        }
        this.$router.go(-1);
        this.loading_update = false;
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + " ",
          color: "error"
        });
        this.loading_update = false;
      })

    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('generic.lang_deleteProject') + ' !',
        text: this.$t('generic.lang_areYouSureYouWantToDeleteTheProject'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post('delete/customer/customerProject/', {
            customerProjectUUID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_projectDeleted'),
                color: "success"
              });

            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.$router.go(-1);
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },

  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
