<template>
    <div>
        <page-title :heading="$t('customers.lang_editProject')" show-previous-button :subheading="$t('customers.lang_editProject')" :icon=icon></page-title>
        <div class="app-main__inner">
            <EditCustomerProjectComponent/>
        </div>
    </div>
</template>

<script>
    import EditCustomerProjectComponent from "../../components/customers/EditCustomerProjectComponent";
    import PageTitle from "../../Layout/Components/PageTitle";
    export default {
        name: "EditCustomerProject",
        components: {PageTitle, EditCustomerProjectComponent},
        data(){
            return{
                icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
            }
        }
    }
</script>

<style scoped>

</style>